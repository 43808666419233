import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';
import {
  MuiButton,
  MuiInputAutosize,
  MuiDatePicker,
} from '@onehope/design-system-ohw';
import { styles } from '@onehope/design-system';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Close from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import { FormikProps, FastField } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { MyFormValues } from './MyFormTypes';
import {
  RadioButtonRow,
  RadioButtonRowGroup,
} from '../../../common/RadioButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CardInfo, CardLine } from '../../Events/AddCustomerForm/index.styles';
import AutoComplete from '../../Events/EventDetailsForm/AutoComplete';
import { allowedStates } from '../../../../../../react-components/checkout/src/Shipment/utils/statesWeCanShipTo';

import AddressAutoCompleteAddressMutation from '../../../mutations/Event/AddressFormAddressAutoCompleteMutation';
import AddressFormGooglePlacesZipMutation from '../../../mutations/Event/AddressFormGooglePlacesZipMutation';
import { Address } from '../../Events/EventDetailsForm/EventDetailsForm';
import { PhoneMask } from './PhoneMask';
import { dateInputFormat } from './helpers';
import {
  SubTitle,
  CheckBoxIcon,
  StyledTypography,
} from '../../Contact/TabContent/Details/Form';
import {
  MiniSelect,
  SelectInput,
  Label,
  DefaultOption,
  OptionLabel,
  ArrowDropDown,
  MenuProps,
} from '../../Events/EventDetailsForm/index.styles';
import { Row, Col, Input } from './index.styles';
import { statesAbbrev } from '../../../utils/statesWeCanShipTo';
const {
  cssConstants: { neutralBackgroundColor, textColor, mutedAccentColor },
} = styles;

interface AddContactProps {
  values: MyFormValues;
  onClose: () => void;
  existingCustomer: any;
  setExistingCustomer: () => void;
  existingCustomerSelected: boolean;
  errorNotification: string | null;
  setErrorNotification: (error: string) => void;
  mutationLoading: boolean;
}

interface CustomerNode {
  firstName: string;
  lastName: string;
  email: string;
}

interface Customer {
  node: CustomerNode;
}

interface CustomerData {
  customerName: string;
  email: string;
  phone: string;
  accountId: string;
}

const Form = styled.div`
  margin: 0 auto;
  width: 100%;
  background-color: ${neutralBackgroundColor};
  padding: 32px 16px;
  @media all and (min-width: 968px) {
    padding: 40px 80px;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    width: 616px;
    /* max-height: 725px; */
  }
`;

const Title = styled.div`
  height: 42px;
  /* font-family: PlayfairDisplay; */
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.5px;
  color: ${textColor};
  margin-bottom: 24px;
`;

const CustomerCard = styled.div`
  max-height: 150px;
  border-radius: 4px;
  background-color: #fff5f5;
  border: solid 1px #f4564e;
  margin-bottom: 28px;
`;

const InputContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  @media all and (min-width: 968px) {
    max-width: 432px;
  }
`;

const Spacer = styled.div`
  padding-bottom: 16px;
`;

const CloseDialog = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  padding-top: 11.4px;
  right: 0;
  cursor: pointer;
  @media all and (min-width: 968px) {
    width: 48px;
    height: 48px;
    padding-top: 21.4px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const DatePicker = styled(MuiDatePicker)`
  && {
    margin-bottom: 25px;
  }
`;

const AdditionalDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AdditionalDetails = styled.div`
  display: flex;
  flex-direction: row;
  height: 43px;
  align-items: center;
  justify-content: space-between;
`;

const AdditionalDetailsText = styled.div`
  height: 22px;
  /* font-family: ProximaNova; */
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${textColor};
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${mutedAccentColor};
  margin-bottom: 21px;
`;

const Padding28 = styled.div`
  padding-bottom: 28px;
`;

const Text = styled.div`
  /* font-family: ProximaNova; */
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${textColor};
  margin-top: -16px;
  margin-bottom: 8px;
`;

const ErrorNotification = styled.div`
  width: 100%;
  /* font-family: ProximaNova; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  border-radius: 4px;
  border: solid 1px #faad14;
  background-color: #fffbf0;
  padding: 14px 22px;
  margin-top: -16px;
  margin-bottom: 24px;
`;

export default function AddContact(
  props: FormikProps<MyFormValues> & AddContactProps,
) {
  const {
    values,
    errors,
    touched,
    onClose,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    setTouched,
    mutationLoading,
    existingCustomer,
    setExistingCustomer,
    errorNotification,
    setErrorNotification,
    initialValues,
  } = props;
  const {
    contactType,
    firstName,
    lastName,
    organizationName,
    email,
    phone,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zip,
    dateOfBirth,
    note,
    hostWineTasting,
    joinWineClub,
    becomePC,
    joinAsCE,
    corporateGifting,
  } = values;

  useEffect(() => {
    if (errors) {
      setTouched({
        firstName: !!initialValues.firstName,
        lastName: !!initialValues.lastName,
        email: !!initialValues.email,
        phone: !!initialValues.phone,
      });
    }
  }, [initialValues]);

  /* MUTATIONS */
  const [
    addressAutoComplete,
    { loading: mutationAddressAutoCompleteLoading },
  ] = useMutation(AddressAutoCompleteAddressMutation);

  const [
    googlePlacesZip,
    { loading: mutationGooglePlacesZipLoading },
  ] = useMutation(AddressFormGooglePlacesZipMutation);

  /* STATE */
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [isAddressMenuOpen, setIsAddressMenuOpen] = useState(false);
  const [addressPredictions, setAddressPredictions] = useState({});

  const handleAddressSelected = async (selectedItem: any) => {
    const { addressLineOne, city, state, zip, placeId } = selectedItem;
    setFieldValue('addressLineOne', addressLineOne);
    setFieldValue('city', city);
    setFieldValue('state', state);
    closeMenu('addressLineOne');
    if (!zip) {
      const variables = {
        input: {
          placeId: placeId,
        },
      };
      return googlePlacesZip({ variables }).then(
        ({
          data: {
            googlePlacesZip: { zip },
          },
        }) => {
          setFieldValue('zip', zip || '');
          // setFieldTouched('addressLineOne', true, false);
        },
      );
    }
    return setFieldTouched('addressLineOne', true, false);
  };

  const handleSetValue = (field: string, selectedItem: string) => {
    setFieldValue(field, selectedItem);
    setFieldTouched(field, true, false);
  };

  const handleSelectChange = (field: string, selectedItem: string) => {
    closeMenu(field);
    handleSetValue(field, selectedItem);
  };

  const handleAddressChange = async (
    event: React.ChangeEvent<{ value: string }>,
  ) => {
    event.preventDefault();
    change('addressLineOne', event);
    const searchTerm = event.target.value;
    const variables = {
      input: {
        query: searchTerm,
      },
    };
    await addressAutoComplete({
      variables,
    }).then((res: any) => {
      const { data } = res;
      setIsAddressMenuOpen(true);
      const predictions =
        data &&
        data.addressAutoComplete &&
        data.addressAutoComplete.addressPredictions;
      let allowedAddresses;
      if (predictions) {
        allowedAddresses = predictions.filter((address: Address) => {
          return (
            allowedStates.includes(address.state) &&
            /\d/.test(address.addressLineOne)
          );
        });
      }
      const listData = allowedAddresses || [];
      const truthyList = listData.filter((address: Address) => {
        return !!address;
      }) as Address[];
      setAddressPredictions(truthyList);
    });
  };

  const handleShowAdditionalDetails = () => {
    setShowAdditionalDetails(!showAdditionalDetails);
  };

  const handleOuterClick = (field: string) => {
    closeMenu(field);
  };

  const closeMenu = (field: string) => {
    switch (field) {
      case 'addressLineOne': {
        setIsAddressMenuOpen(false);
        break;
      }
    }
  };

  const change = (name: any, e: React.SyntheticEvent) => {
    if (name === 'email') {
      setExistingCustomer('');
      setErrorNotification(null);
    }
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const toggle = (name: any, e: React.SyntheticEvent) => {
    e.persist();
    setFieldValue(name, e.target.checked);
    setFieldTouched(name, true, false);
  };

  const textChangeValid = (event: React.SyntheticEvent) => {
    return event.target.value.length <= 140;
  };

  const disableSubmit = mutationLoading || !isEmpty(errors) || isEmpty(touched);
  const isPerson = contactType === 'PERSON';
  return (
    <form onSubmit={handleSubmit}>
      <CloseDialog onClick={onClose}>
        <Close />
      </CloseDialog>
      <Form>
        <Title>Add new contact</Title>
        <>
          <InputContainer>
            <RadioButtonRowGroup
              id="contactType"
              label=""
              value={contactType}
              error={errors.contactType}
              touched={touched.contactType}
            >
              <FastField
                component={RadioButtonRow}
                name="contactType"
                id="PERSON"
                label="A person"
              />
              <FastField
                component={RadioButtonRow}
                name="contactType"
                id="ORGANIZATION"
                label="An organization"
              />
            </RadioButtonRowGroup>
            {!isPerson && (
              <Input
                label="Name of Organization"
                placeholder="Mack's Magical Muffins"
                id="organizationName"
                name="organizationName"
                value={organizationName}
                fullWidth
                validate
                hasError={
                  !isSubmitting &&
                  touched.organizationName &&
                  Boolean(errors.organizationName)
                }
                hasSuccess={
                  !isSubmitting &&
                  touched.organizationName &&
                  !Boolean(errors.organizationName)
                }
                error={
                  touched.organizationName && Boolean(errors.organizationName)
                }
                helperText={
                  errors.organizationName &&
                  touched.organizationName &&
                  errors.organizationName
                }
                onChange={(e: React.SyntheticEvent) =>
                  change('organizationName', e)
                }
              />
            )}
            <Input
              label={isPerson ? 'First name' : "Contact's first name"}
              placeholder="Jane"
              id="firstName"
              name="firstName"
              value={firstName}
              fullWidth
              validate
              hasError={
                !isSubmitting && touched.firstName && Boolean(errors.firstName)
              }
              hasSuccess={
                !isSubmitting && touched.firstName && !Boolean(errors.firstName)
              }
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={
                errors.firstName && touched.firstName && errors.firstName
              }
              onChange={(e: React.SyntheticEvent) => change('firstName', e)}
            />
            <Input
              label={isPerson ? 'Last name' : "Contact's last name"}
              placeholder="Austen"
              id="lastName"
              name="lastName"
              value={lastName}
              fullWidth
              validate
              hasError={
                !isSubmitting && touched.lastName && Boolean(errors.lastName)
              }
              hasSuccess={
                !isSubmitting && touched.lastName && !Boolean(errors.lastName)
              }
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={
                errors.lastName && touched.lastName && errors.lastName
              }
              onChange={(e: React.SyntheticEvent) => change('lastName', e)}
            />
            <Input
              label="Email address"
              placeholder="jane@gmail.com"
              id="email"
              name="email"
              value={email}
              fullWidth
              validate
              focusError={existingCustomer}
              hasError={!isSubmitting && touched.email && Boolean(errors.email)}
              hasSuccess={
                !isSubmitting && touched.email && !Boolean(errors.email)
              }
              error={touched.email && Boolean(errors.email)}
              helperText={errors.email && touched.email && errors.email}
              onChange={(e: React.SyntheticEvent) => change('email', e)}
            />
            {existingCustomer && (
              <div>
                <Text>A contact with this email already exists!</Text>
                <CustomerCard>
                  <CardInfo>
                    <CardLine>
                      {`${existingCustomer.firstName} ${existingCustomer.lastName}` ||
                        ''}
                    </CardLine>
                    <CardLine>{existingCustomer.email || ''}</CardLine>
                    {existingCustomer && (
                      <CardLine>{existingCustomer.phone || ''}</CardLine>
                    )}
                  </CardInfo>
                </CustomerCard>
              </div>
            )}
            {errorNotification && (
              <ErrorNotification>{errorNotification}</ErrorNotification>
            )}
            <AutoComplete
              id="shippingAddressDropDown"
              inputId="addressLineOne"
              label="Shipping address (optional)"
              placeholder="123 Madison ave"
              value={addressLineOne}
              error={errors.addressLineOne}
              touched={touched.addressLineOne}
              isSubmitting={isSubmitting}
              isWithinDialog={false}
              isMenuOpen={isAddressMenuOpen}
              handleAddressSelected={handleAddressSelected}
              handleInputChange={handleAddressChange}
              handleOuterClick={handleOuterClick}
              objectPredictions={addressPredictions}
            />
            <Input
              label="Address 2"
              placeholder="Building, Suite, or Apt #"
              id="addressLineTwo"
              name="addressLineTwo"
              value={addressLineTwo}
              fullWidth
              hasError={
                !isSubmitting &&
                touched.addressLineTwo &&
                Boolean(errors.addressLineTwo)
              }
              hasSuccess={
                !isSubmitting &&
                touched.addressLineTwo &&
                !Boolean(errors.addressLineTwo)
              }
              error={touched.addressLineTwo && Boolean(errors.addressLineTwo)}
              helperText={
                errors.addressLineTwo &&
                touched.addressLineTwo &&
                errors.addressLineTwo
              }
              onChange={(e: React.SyntheticEvent) =>
                change('addressLineTwo', e)
              }
            />
            <Row>
              <Col>
                <Input
                  label="City"
                  placeholder="Los Angeles"
                  id="city"
                  name="city"
                  value={city}
                  width="264"
                  fullWidth
                  hasError={
                    !isSubmitting && touched.city && Boolean(errors.city)
                  }
                  hasSuccess={
                    !isSubmitting && touched.city && !Boolean(errors.city)
                  }
                  error={touched.city && Boolean(errors.city)}
                  helperText={errors.city && touched.city && errors.city}
                  onChange={(e: React.SyntheticEvent) => change('city', e)}
                />
              </Col>
              <Col>
                <Label htmlFor="State">State</Label>
                <MiniSelect
                  IconComponent={ArrowDropDown}
                  input={
                    <SelectInput
                      id="state"
                      hasError={touched.state && Boolean(errors.state)}
                      width="144"
                    />
                  }
                  value={state}
                  displayEmpty
                  onChange={(e: React.SyntheticEvent) => change('state', e)}
                  inputProps={{
                    name: 'state',
                    id: 'state',
                    MenuProps: MenuProps,
                  }}
                >
                  >
                  <MenuItem component="li" value="">
                    <DefaultOption>Select one</DefaultOption>
                  </MenuItem>
                  {statesAbbrev.map(state => (
                    <MenuItem
                      button={true}
                      component="li"
                      key={state}
                      value={state}
                    >
                      <OptionLabel>{state}</OptionLabel>
                    </MenuItem>
                  ))}
                </MiniSelect>
              </Col>
            </Row>
            <Input
              label="Zipcode"
              placeholder="90210"
              id="zip"
              name="zip"
              value={zip}
              width="146"
              fullWidth
              hasError={!isSubmitting && touched.zip && Boolean(errors.zip)}
              hasSuccess={!isSubmitting && touched.zip && !Boolean(errors.zip)}
              error={touched.zip && Boolean(errors.zip)}
              helperText={errors.zip && touched.zip && errors.zip}
              onChange={(e: React.SyntheticEvent) => change('zip', e)}
            />
            <Input
              label="Mobile phone # (optional)"
              placeholder="(555) 123 - 4567"
              id="phone"
              name="phone"
              value={phone}
              fullWidth
              validate
              hasError={!isSubmitting && touched.phone && Boolean(errors.phone)}
              hasSuccess={
                !isSubmitting && touched.phone && !Boolean(errors.phone)
              }
              mask={PhoneMask}
              error={touched.phone && Boolean(errors.phone)}
              helperText={errors.phone && touched.phone && errors.phone}
              onChange={(e: React.SyntheticEvent) => change('phone', e)}
            />
            <AdditionalDetailsContainer>
              <AdditionalDetails>
                <AdditionalDetailsText>
                  Additional Details
                </AdditionalDetailsText>
                {showAdditionalDetails ? (
                  <RemoveIcon
                    fontSize="small"
                    onClick={handleShowAdditionalDetails}
                  />
                ) : (
                  <AddIcon
                    fontSize="small"
                    onClick={handleShowAdditionalDetails}
                  />
                )}
              </AdditionalDetails>
              <HorizontalLine />
            </AdditionalDetailsContainer>
            {showAdditionalDetails && (
              <>
                <Input
                  label="Birthday (optional)"
                  placeholder="MM / DD / YYYY"
                  defaultChecked={false}
                  id="dateOfBirth"
                  name="dateOfBirth"
                  fullWidth
                  onChange={e => {
                    handleSelectChange(
                      'dateOfBirth',
                      dateInputFormat(e.target?.value),
                    );
                  }}
                  value={dateOfBirth}
                  helperText={
                    errors.dateOfBirth &&
                    touched.dateOfBirth &&
                    errors.dateOfBirth
                  }
                  hasSuccess={
                    !isSubmitting &&
                    touched.dateOfBirth &&
                    !Boolean(errors.dateOfBirth)
                  }
                  hasError={
                    !isSubmitting &&
                    touched.dateOfBirth &&
                    Boolean(errors.dateOfBirth)
                  }
                />
                <MuiInputAutosize
                  label="Write a new note"
                  rows={1}
                  rowsMax={16}
                  maxCount="140"
                  placeholder={'Enter note here'}
                  value={note}
                  name="note"
                  id="note"
                  validInput={note.length > 0}
                  onChange={(e: React.SyntheticEvent) => {
                    if (textChangeValid(e)) {
                      change('note', e);
                    }
                  }}
                />
                <SubTitle editing={false}>Interested in:</SubTitle>
                <FormGroup>
                  <FormControlLabel
                    label={
                      <StyledTypography disabled={false}>
                        Host a wine tasting
                      </StyledTypography>
                    }
                    disabled={false}
                    control={
                      <CheckBoxIcon
                        editing={false}
                        checked={hostWineTasting}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          toggle('hostWineTasting', e)
                        }
                        value="hostWineTasting"
                      />
                    }
                  />
                  <FormControlLabel
                    label={
                      <StyledTypography disabled={false}>
                        Joining Wine Club
                      </StyledTypography>
                    }
                    disabled={false}
                    control={
                      <CheckBoxIcon
                        editing={false}
                        checked={joinWineClub}
                        onChange={(e: React.SyntheticEvent) =>
                          toggle('joinWineClub', e)
                        }
                        value="joinWineClub"
                      />
                    }
                  />
                  <FormControlLabel
                    label={
                      <StyledTypography disabled={false}>
                        Joining as a Cause Entrepreneur
                      </StyledTypography>
                    }
                    disabled={false}
                    control={
                      <CheckBoxIcon
                        editing={false}
                        checked={joinAsCE}
                        onChange={(e: React.SyntheticEvent) =>
                          toggle('joinAsCE', e)
                        }
                        value="joinAsCE"
                      />
                    }
                  />
                  <FormControlLabel
                    label={
                      <StyledTypography disabled={false}>
                        Corporate Gifting
                      </StyledTypography>
                    }
                    disabled={false}
                    control={
                      <CheckBoxIcon
                        editing={false}
                        checked={corporateGifting}
                        onChange={(e: React.SyntheticEvent) =>
                          toggle('corporateGifting', e)
                        }
                        value="corporateGifting"
                      />
                    }
                  />
                </FormGroup>
                <Padding28 />
              </>
            )}
          </InputContainer>
          <ButtonContainer>
            <MuiButton
              type="PRIMARY"
              disableRipple
              disabled={disableSubmit}
              onClick={handleSubmit}
            >
              save
            </MuiButton>
            <Spacer />
            <MuiButton type="TERTIARY" disableRipple onClick={onClose}>
              cancel
            </MuiButton>
          </ButtonContainer>
        </>
      </Form>
    </form>
  );
}
